// картинка коврика ромб опредилёного цвета
export const R_RUG_COLOR_IMG = [
  {
    img: '/images/constructor/mainImg/r_rug_color/r_bk-500x500.png',
    value: 'r_bk',
    title: 'Чорний',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color/r_gr-500x500.png',
    value: 'r_gr',
    title: 'Сірий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color/r_br-500x500.png',
    value: 'r_br',
    title: 'Коричневий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color/r_bg-500x500.png',
    value: 'r_bg',
    title: 'Бежевий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color/r_kr-500x500.png',
    value: 'r_kr',
    title: 'Кремовий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color/r_rd-500x500.png',
    value: 'r_rd',
    title: 'Червоний',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color/r_bl-500x500.png',
    value: 'r_bl',
    title: 'Синій',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color/r_ml-500x500.png',
    value: 'r_ml',
    title: 'Хакі',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color/r_bd-500x500.png',
    value: 'r_bd',
    title: 'Бордовий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color/r_dbl-500x500.png',
    value: 'r_dbl',
    title: 'Темно-сіиній',
  },
];

// картинка коврика сота опредилёного цвета
export const S_RUG_COLOR_IMG = [
  {
    img: '/images/constructor/mainImg/s_rug_color/s_bk_bk-500x500.png',
    value: 's_bk',
    title: 'Чорний',
  },
  {
    img: '/images/constructor/mainImg/s_rug_color/s_bk_bk-500x500.png',
    value: 's_gr',
    title: 'Сірий',
  },
  {
    img: '/images/constructor/mainImg/s_rug_color/s_bk_bk-500x500.png',
    value: 's_br',
    title: 'Коричневий',
  },
  {
    img: '/images/constructor/mainImg/s_rug_color/s_bk_bk-500x500.png',
    value: 's_bg',
    title: 'Бежевий',
  },
  {
    img: '/images/constructor/mainImg/s_rug_color/s_bk_bk-500x500.png',
    value: 's_kr',
    title: 'Кремовий',
  },
  {
    img: '/images/constructor/mainImg/s_rug_color/s_bk_bk-500x500.png',
    value: 's_rd',
    title: 'Червоний',
  },
  {
    img: '/images/constructor/mainImg/s_rug_color/s_bk_bk-500x500.png',
    value: 's_bl',
    title: 'Синій',
  },
  {
    img: '/images/constructor/mainImg/s_rug_color/s_bk_bk-500x500.png',
    value: 's_ml',
    title: 'Хакі',
  },
  {
    img: '/images/constructor/mainImg/s_rug_color/s_bk_bk-500x500.png',
    value: 's_bd',
    title: 'Бордовий',
  },
  {
    img: '/images/constructor/mainImg/s_rug_color/s_bk_bk-500x500.png',
    value: 's_dbl',
    title: 'Темно-сіиній',
  },
];

// картинка кантика на класический коврик
export const EDGE_COLOR_CLASSIC = [
  {
    img: '/images/constructor/mainImg/edge_color/bk-500x500.png',
    value: 'bk',
    title: 'Чорний',
  },
  {
    img: '/images/constructor/mainImg/edge_color/gr-500x500.png',
    value: 'gr',
    title: 'Сірий',
  },
  {
    img: '/images/constructor/mainImg/edge_color/br-500x500.png',
    value: 'br',
    title: 'Коричневий',
  },
  {
    img: '/images/constructor/mainImg/edge_color/bg-500x500.png',
    value: 'bg',
    title: 'Бежевий',
  },
  {
    img: '/images/constructor/mainImg/edge_color/kr-500x500.png',
    value: 'kr',
    title: 'Кремовий',
  },
  {
    img: '/images/constructor/mainImg/edge_color/rd-500x500.png',
    value: 'rd',
    title: 'Червоний',
  },
  {
    img: '/images/constructor/mainImg/edge_color/bl-500x500.png',
    value: 'bl',
    title: 'Синій',
  },
  {
    img: '/images/constructor/mainImg/edge_color/gn-500x500.png',
    value: 'gn',
    title: 'Зелений',
  },
  {
    img: '/images/constructor/mainImg/edge_color/or-500x500.png',
    value: 'or',
    title: 'Помаранчевий',
  },
  {
    img: '/images/constructor/mainImg/edge_color/yl-500x500.png',
    value: 'yl',
    title: 'Жовтий',
  },
  {
    img: '/images/constructor/mainImg/edge_color/ml-500x500.png',
    value: 'ml',
    title: 'Хакі',
  },
  {
    img: '/images/constructor/mainImg/edge_color/bd-500x500.png',
    value: 'bd',
    title: 'Бордовий',
  },
  {
    img: '/images/constructor/mainImg/edge_color/pur-500x500.png',
    value: 'pur',
    title: 'Фіолетовий',
  },
  {
    img: '/images/constructor/mainImg/edge_color/dbl-500x500.png',
    value: 'dbl',
    title: 'Темно-синій',
  },
];

/* ------------------------------- 3Д КОВРИКИ ------------------------------- */
// картинка коврика ромб опредилёного цвета
export const R_RUG_COLOR_IMG_3D = [
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bk_3d-500x500.png',
    value: 'r_bk',
    title: 'Чорний',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_gr_3d-500x500.png',
    value: 'r_gr',
    title: 'Сірий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_br_3d-500x500.png',
    value: 'r_br',
    title: 'Коричневий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bg_3d-500x500.png',
    value: 'r_bg',
    title: 'Бежевий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_kr_3d-500x500.png',
    value: 'r_kr',
    title: 'Кремовий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_rd_3d-500x500.png',
    value: 'r_rd',
    title: 'Червоний',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bl_3d-500x500.png',
    value: 'r_bl',
    title: 'Синій',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_ml_3d-500x500.png',
    value: 'r_ml',
    title: 'Хакі',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bd_3d-500x500.png',
    value: 'r_bd',
    title: 'Бордовий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_dbl_3d-500x500.png',
    value: 'r_dbl',
    title: 'Темно-сіиній',
  },
];

// картинка коврика сота опредилёного цвета
export const S_RUG_COLOR_IMG_3D = [
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bk_bk_3d-500x500.png',
    value: 's_bk',
    title: 'СОТА Чорний',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bk_bk_3d-500x500.png',
    value: 's_gr',
    title: 'СОТА Сірий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bk_bk_3d-500x500.png',
    value: 's_br',
    title: 'СОТА Коричневий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bk_bk_3d-500x500.png',
    value: 's_bg',
    title: 'СОТА Бежевий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bk_bk_3d-500x500.png',
    value: 's_kr',
    title: 'СОТА Кремовий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bk_bk_3d-500x500.png',
    value: 's_rd',
    title: 'СОТА Червоний',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bk_bk_3d-500x500.png',
    value: 's_bl',
    title: 'СОТА Синій',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bk_bk_3d-500x500.png',
    value: 's_ml',
    title: 'СОТА Хакі',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bk_bk_3d-500x500.png',
    value: 's_bd',
    title: 'СОТА Бордовий',
  },
  {
    img: '/images/constructor/mainImg/r_rug_color_3d/r_bk_bk_3d-500x500.png',
    value: 's_dbl',
    title: 'СОТА Темно-сіиній',
  },
];

// картинка кантика на 3D коврик
export const EDGE_COLOR_3D = [
  {
    img: '/images/constructor/mainImg/edge_color_3d/bk_3d-500x500.png',
    value: 'bk',
    title: 'Чорний',
  },
  {
    img: '/images/constructor/mainImg/edge_color_3d/gr_3d-500x500.png',
    value: 'gr',
    title: 'Сірий',
  },
  {
    img: '/images/constructor/mainImg/edge_color_3d/br_3d-500x500.png',
    value: 'br',
    title: 'Коричневий',
  },
  // {
  //   img: "/images/constructor/mainImg/edge_color_3d/bg_3d-500x500.png",
  //   value: "bg",
  //   title: "Бежевий",
  // },
  {
    img: '/images/constructor/mainImg/edge_color_3d/pur_3d-500x500.png',
    value: 'pur',
    title: 'Фіолетовий',
  },
  {
    img: '/images/constructor/mainImg/edge_color_3d/kr_3d-500x500.png',
    value: 'kr',
    title: 'Кремовий',
  },
  {
    img: '/images/constructor/mainImg/edge_color_3d/rd_3d-500x500.png',
    value: 'rd',
    title: 'Червоний',
  },
  {
    img: '/images/constructor/mainImg/edge_color_3d/bl_3d-500x500.png',
    value: 'bl',
    title: 'Синій',
  },
  {
    img: '/images/constructor/mainImg/edge_color_3d/gn_3d-500x500.png',
    value: 'gn',
    title: 'Зелений',
  },
  {
    img: '/images/constructor/mainImg/edge_color_3d/or_3d-500x500.png',
    value: 'or',
    title: 'Помаранчевий',
  },
  {
    img: '/images/constructor/mainImg/edge_color_3d/yl_3d-500x500.png',
    value: 'yl',
    title: 'Жовтий',
  },
  {
    img: '/images/constructor/mainImg/edge_color_3d/ml_3d-500x500.png',
    value: 'ml',
    title: 'Хакі',
  },
  {
    img: '/images/constructor/mainImg/edge_color_3d/bd_3d-500x500.png',
    value: 'bd',
    title: 'Бордовий',
  },
  {
    img: '/images/constructor/mainImg/edge_color_3d/dbl_3d-500x500.png',
    value: 'dbl',
    title: 'Темно-синій',
  },
];
