import { createSlice } from "@reduxjs/toolkit";
import { OPTIONS } from "../../pages/Accessories/mocs";
const initialState = {
  imgAcc: "",
  title: "",
  typeM: "", //тип материала ева\эко
  size: "", //размер аксесуара
  color: "", //ЦВЕТ
  sizeOptions: [],
  colorOptions: [],

  price: 0,
};

const accessoriesSlice = createSlice({
  name: "accessories",
  initialState,
  reducers: {
    popelateInitialState: (state, { payload }) => {
      const { img, title, price } = payload;
      state.imgAcc = img;
      state.title = title;
      state.typeM = "eva";
      state.price = price;

      state.sizeOptions =
        OPTIONS.find((option) => option.type === "size") || [];
      state.colorOptions =
        OPTIONS.find((option) => option.type === "color") || [];
    },

    selectTypeM: (state, { payload }) => {
      state.typeM = payload;
    },

    selectSize: (state, { payload }) => {
      state.size = payload;
    },

    selectColor: (state, { payload }) => {
      state.color = payload;
    },

    cleanState: (state) => {
      state.imgAcc = "";
      state.title = "";
      state.typeM = "";
      state.price = 0;
      state.size = "";
      state.color = "";
    },
  },
});

export const {
  popelateInitialState,
  selectTypeM,
  selectSize,
  selectColor,
  cleanState,
} = accessoriesSlice.actions;

export const accessoriesReducer = accessoriesSlice.reducer;
