import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // Это по умолчанию использует localStorage для веба

import { filterReducer } from "./filter/filter";
import { constructorRugReducer } from "./constructor/constructor";
import { basketReducer } from "./basket/basket";
import { accessoriesReducer } from "./accessories/accessories";

// Создаем persistConfig
const persistConfig = {
  key: "root", // Ключ для хранения состояния
  storage,
  whitelist: ["filter", "constructorRug", "basket", "accessories"], // Перечень редьюсеров, которые нужно сохранять
};

// Комбинируем редьюсеры
const rootReducer = combineReducers({
  filter: filterReducer,
  constructorRug: constructorRugReducer,
  basket: basketReducer,
  accessories: accessoriesReducer,
});

// Оборачиваем rootReducer в persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
