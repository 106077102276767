import { createSlice } from "@reduxjs/toolkit";
import { uid } from "uid";

const createRugOrder = (data) => {
  return {
    id: uid(),
    type: "rug",
    imgModel: "",
    title: "",
    salonScheme: [], //записываем схему салона 1 или 2
    typeM: "", //тип материала ромб\сота
    colorRug: "", //ЦВЕТ КОВРИКА
    colorEdge: "", //ЦВЕТ КАНТИКА
    heel: false, //подпятник да или нет
    brandLabel: "", //шильдик да или нет
    typeBoart: "", //ТИП БОРТИКА 5см 10см
    typeRug: "", //ТИП КОВРИКА КЛАСИЧЕСКИЙ ИЛИ 3Д...
    titleKey: "",
    price: 0,

    count: 1,
    totalPrice: 0,
    ...data,
  };
};

const createAccessoriesOrder = (data) => {
  return {
    id: uid(),
    type: "accessory",
    imgAcc: "",
    title: "",
    size: "", //размер аксесуара
    typeM: "", //тип материала ева\эко
    color: "", //ЦВЕТ
    price: 0,

    count: 1,
    totalPrice: 0,
    ...data,
  };
};

const initialState = {
  orders: [], //суда записивать обьект с данными, для отображения в корзине
  name: "",
  totalPrice: 0,
};

const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    addRugOrder: (state, { payload }) => {
      const newOrder = createRugOrder(payload);
      newOrder.totalPrice = newOrder.price * newOrder.count;
      state.orders.push(newOrder);
      state.totalPrice = state.orders.reduce(
        (acc, order) => acc + order.totalPrice,
        0
      );
    },

    addAccessoriesOrder: (state, { payload }) => {
      const newOrder = createAccessoriesOrder(payload);
      newOrder.totalPrice = newOrder.price * newOrder.count;
      state.orders.push(newOrder);
      state.totalPrice = state.orders.reduce(
        (acc, order) => acc + order.totalPrice,
        0
      );
    },

    decrement: (state, { payload }) => {
      const order = state.orders.find((order) => order.id === payload.id);
      if (order && order.count > 1) {
        order.count -= 1;
        order.totalPrice -= order.price;
        state.totalPrice = state.orders.reduce(
          (acc, order) => acc + order.totalPrice,
          0
        );
      }
    },

    increment: (state, { payload }) => {
      const order = state.orders.find((order) => order.id === payload.id);
      if (order) {
        order.count += 1;
        order.totalPrice += order.price;
        state.totalPrice = state.orders.reduce(
          (acc, order) => acc + order.totalPrice,
          0
        );
      }
    },

    deleteOrder: (state, { payload }) => {
      state.orders = state.orders.filter(({ id }) => payload !== id);
      state.totalPrice = state.orders.reduce((acc, order) => {
        return acc + parseFloat(order.price);
      }, 0);
    },

    clearingOrders: (state) => {
      state.orders = [];
    },

    getName: (state, { payload }) => {
      state.name = payload;
    },

    deleteName: (state) => {
      state.name = "";
    },
  },
});

export const {
  addRugOrder,
  addAccessoriesOrder,
  deleteOrder,
  increment,
  decrement,
  clearingOrders,
  getName,
  deleteName,
} = basketSlice.actions;

export const basketReducer = basketSlice.reducer;

// const originalObject = JSON.parse(JSON.stringify(state));
// console.log("basket state", originalObject);
