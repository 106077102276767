import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
// import Layout from './components/Layout/Layout';

// import AccessoriesCard from "./pages/AccessoriesCard/AccessoriesCard";

const Layout = lazy(() => import('./components/Layout/Layout'));
const Constructor = lazy(() => import('./pages/Constructor/Constructor'));
const Accessories = lazy(() => import('./pages/Accessories/Accessories'));
const AccessoriesCard = lazy(() => import('./pages/AccessoriesCard/AccessoriesCard'));
const Delivery = lazy(() => import('./pages/textPages/Delivery/Delivery'));
const Guarantees = lazy(() => import('./pages/textPages/Guarantees'));
const Partners = lazy(() => import('./pages/textPages/Partners'));
const Exchange = lazy(() => import('./pages/textPages/Exchange'));
const Catalog = lazy(() => import('./pages/Catalog/Catalog'));
const Patents = lazy(() => import('./pages/Patents/Patents'));
const Page404 = lazy(() => import('./pages/Page404/Page404'));
const About = lazy(() => import('./pages/textPages/About'));
const Basket = lazy(() => import('./pages/Basket/Basket'));
const Models = lazy(() => import('./pages/Cars/Models'));
const Home = lazy(() => import('./pages/Home'));

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/:brandName" element={<Models />} />
        <Route path="/accessories" element={<Accessories />} />
        <Route path="/accessories/:cardId" element={<AccessoriesCard />} />

        <Route path="/partners" element={<Partners />} />
        <Route path="/exchange" element={<Exchange />} />
        <Route path="/patents" element={<Patents />} />
        <Route path="/guarantees" element={<Guarantees />} />
        <Route path="/delivery" element={<Delivery />} />

        <Route path="/constructor" element={<Constructor />} />
        <Route path="/constructor/:modelId" element={<Constructor />} />
        <Route path="/basket" element={<Basket />} />
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}

export default App;
