// import { uid } from "uid";

export const CTALOG_ACCESSORIES = [
  {
    id: "1",
    img: "/images/accessories/eva/plannerMINI/1.webp",
    imgList: [
      "/images/accessories/eva/plannerMINI/1.webp",
      "/images/accessories/eva/plannerMINI/2.webp",
      "/images/accessories/eva/plannerMINI/3.webp",
    ],
    title: "Органайзер EVA Mini",
    price: 980,
    text: (
      <>
        <strong>Наші органайзери з EVA матеріалу</strong> - це чудове рішення
        для зберігання речей в автомобілі. EVA (етиленвінілацетат) - це легкий,
        міцний і водонепроникний матеріал, який забезпечує надійний захист ваших
        речей від вологи та пошкоджень. Органайзери з EVA матеріалу
        відрізняються високою зносостійкістю і легкістю у догляді. Вони ідеально
        підходять для зберігання різноманітних предметів, забезпечуючи порядок і
        зручність у вашому автомобілі. Обираючи наші органайзери, ви отримуєте
        надійний і функціональний продукт, який полегшить ваші подорожі та
        зробить їх комфортнішими.
      </>
    ),
  },
  {
    id: "2",
    img: "/images/accessories/eva/plannerNEW/1.webp",
    imgList: [
      "/images/accessories/eva/plannerNEW/1.webp",
      "/images/accessories/eva/plannerNEW/2.webp",
      "/images/accessories/eva/plannerNEW/3.webp",
      "/images/accessories/eva/plannerNEW/4.webp",
      "/images/accessories/eva/plannerNEW/5.webp",
    ],
    title: "Органайзер EVA Maxi",
    price: 1600,
    text: (
      <>
        <strong>Наші органайзери з EVA матеріалу</strong> - це чудове рішення
        для зберігання речей в автомобілі. EVA (етиленвінілацетат) - це легкий,
        міцний і водонепроникний матеріал, який забезпечує надійний захист ваших
        речей від вологи та пошкоджень. Органайзери з EVA матеріалу
        відрізняються високою зносостійкістю і легкістю у догляді. Вони ідеально
        підходять для зберігання різноманітних предметів, забезпечуючи порядок і
        зручність у вашому автомобілі. Обираючи наші органайзери, ви отримуєте
        надійний і функціональний продукт, який полегшить ваші подорожі та
        зробить їх комфортнішими.
      </>
    ),
  },
  {
    id: "3",
    img: "/images/accessories/eco/whiteBull/1.webp",
    imgList: [
      "/images/accessories/eco/whiteBull/1.webp",
      "/images/accessories/eco/whiteBull/2.webp",
      "/images/accessories/eco/whiteBull/3.webp",
      "/images/accessories/eco/whiteBull/4.webp",
    ],
    title: "Органайзер ECO L whiteBull",
    price: 2050,
    text: (
      <>
        <strong>Наші органайзери з ЕКО шкіри</strong> - це ідеальне рішення для
        підтримання порядку в автомобілі. Вони виготовлені з високоякісної еко
        шкіри, яка поєднує в собі стильний вигляд, довговічність і
        екологічність. Органайзери допоможуть зручно зберігати ваші речі,
        забезпечуючи швидкий доступ до всього необхідного під час подорожей.
        Кожен виріб продуманий до дрібниць, щоб забезпечити максимальний комфорт
        і функціональність. Обираючи наші органайзери, ви отримуєте якісний
        продукт, який стане незамінним помічником у вашому авто.
      </>
    ),
  },
  {
    id: "4",
    img: "/images/accessories/eco/blackBlue/1.webp",
    imgList: [
      "/images/accessories/eco/blackBlue/1.webp",
      "/images/accessories/eco/blackBlue/2.webp",
      "/images/accessories/eco/blackBlue/3.webp",
    ],
    title: "Органайзер ECO L",
    price: 2050,
    text: (
      <>
        <strong>Наші органайзери з ЕКО шкіри</strong> - це ідеальне рішення для
        підтримання порядку в автомобілі. Вони виготовлені з високоякісної еко
        шкіри, яка поєднує в собі стильний вигляд, довговічність і
        екологічність. Органайзери допоможуть зручно зберігати ваші речі,
        забезпечуючи швидкий доступ до всього необхідного під час подорожей.
        Кожен виріб продуманий до дрібниць, щоб забезпечити максимальний комфорт
        і функціональність. Обираючи наші органайзери, ви отримуєте якісний
        продукт, який стане незамінним помічником у вашому авто.
      </>
    ),
  },
  // {
  //   id: uid(),
  //   // img: "/images/gallery/accessories/img-2.webp",
  //   img: "/images/gallery/accessories/1.webp",
  //   imgList: [
  //     "/images/gallery/accessories/1.webp",
  //     "/images/gallery/accessories/2.webp",
  //     "/images/gallery/accessories/3.webp",
  //     "/images/gallery/accessories/4.webp",
  //     "/images/gallery/accessories/5.webp",
  //   ],
  //   title: "Органайзер 2",
  //   price: 2000,
  //   text: "Lorem ipsum dolor sit amet consectetur. At in nunc gravida in amet morbi volutpat.",
  // },
  // {
  //   id: uid(),
  //   // img: "/images/gallery/accessories/1.webp",
  //   img: "/images/gallery/accessories/1.webp",
  //   imgList: [
  //     "/images/gallery/accessories/1.webp",
  //     "/images/gallery/accessories/2.webp",
  //     "/images/gallery/accessories/3.webp",
  //     "/images/gallery/accessories/4.webp",
  //     "/images/gallery/accessories/5.webp",
  //   ],
  //   title: "Органайзер 3",
  //   price: 1900,
  //   text: "Lorem ipsum dolor sit amet consectetur. At in nunc gravida in amet morbi volutpat.",
  // },
  // {
  //   id: uid(),
  //   // img: "/images/gallery/accessories/1.webp",
  //   img: "/images/gallery/accessories/1.webp",
  //   imgList: [
  //     "/images/gallery/accessories/1.webp",
  //     "/images/gallery/accessories/2.webp",
  //     "/images/gallery/accessories/3.webp",
  //     "/images/gallery/accessories/4.webp",
  //     "/images/gallery/accessories/5.webp",
  //   ],
  //   title: "Органайзер 4",
  //   price: 1700,
  //   text: "Lorem ipsum dolor sit amet consectetur. At in nunc gravida in amet morbi volutpat.",
  // },
];

export const OPTIONS = [
  {
    type: "size",
    values: [
      { label: "35*30*25", value: "35*30*25" },
      { label: "60*20*25", value: "60*20*25" },
      { label: "27*30*44", value: "27*30*44" },
      { label: "36*36*40", value: "36*36*40" },
      { label: "30*29*79", value: "30*29*79" },
      { label: "30*30*48", value: "30*30*48" },
      { label: "30*30*60", value: "30*30*60" },
      { label: "30*30*80", value: "30*30*80" },
    ],
  },
  {
    type: "color",
    values: [
      { label: "Сірий", value: "Сірий" },
      { label: "Чорний", value: "Чорний" },
      { label: "Коричневий", value: "Коричневий" },
      { label: "Бежевий", value: "Бежевий" },
      { label: "Бордовий", value: "Бордовий" },
      { label: "Темно-синій", value: "Темно-синій" },
      { label: "Хакі", value: "Хакі" },
      { label: "Бежевий", value: "Бежевий" },
      { label: "Чорний з чорною ниткою", value: "Чорний з чорною ниткою" },
      { label: "Чорний з червоною ниткою", value: "Чорний з червоною ниткою" },
      { label: "Чорний з синьою ниткою", value: "Чорний з синьою ниткою" },
    ],
  },
];
