import MoonLoader from 'react-spinners/MoonLoader';
import { useState } from 'react';
import { css } from '@emotion/react';
import styled from 'styled-components';

const override = css`
  display: "block",
  margin: "0 auto",
  
`;

const Spinner = () => {
  let [loading] = useState(true);
  let [color] = useState('#ee1c25');
  return (
    <SpinnerPosition>
      <MoonLoader color={color} loading={loading} cssOverride={override} size={80} />
    </SpinnerPosition>
  );
};

export default Spinner;

const SpinnerPosition = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;
